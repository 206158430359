/**
 * @generated SignedSource<<c4ce28c4967a04b41b09235c1da30640>>
 * @relayHash 8adb9c0c16fe5182c4b2a6945e32df60
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d50481bd42a382b4c4ffa9a790d41781c22c541999359c35258076f7c1ab96d5

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type srcHelpCenterVirtualAgentSettingsQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  isHideKbColumnEnabled: boolean;
  isJsmAIConfigEnabled: boolean;
  isVAOnHelpCenterGAEnabled: boolean;
};
export type srcHelpCenterVirtualAgentSettingsQuery$data = {
  readonly helpCenter?: {
    readonly helpCenterById: {
      readonly __typename: string;
      readonly message?: string | null | undefined;
      readonly virtualAgentEnabled?: boolean | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"settingsBreadcrumbsFragment" | "settingsVirtualAgentSettingsTabFragment">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"settingsVirtualAgentSettingsTabMappedProjectsFragment">;
};
export type srcHelpCenterVirtualAgentSettingsQuery = {
  response: srcHelpCenterVirtualAgentSettingsQuery$data;
  variables: srcHelpCenterVirtualAgentSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHideKbColumnEnabled"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isJsmAIConfigEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isVAOnHelpCenterGAEnabled"
},
v5 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "kind": "Variable",
  "name": "helpCenterAri",
  "variableName": "helpCenterAri"
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "ScalarField",
  "name": "virtualAgentEnabled"
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ],
  "type": "QueryError"
},
v12 = {
  "kind": "ScalarField",
  "name": "id"
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "kind": "InlineFragment",
  "selections": (v13/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v15 = [
  (v5/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "helpCenterARI",
        "variableName": "helpCenterAri"
      },
      {
        "kind": "Literal",
        "name": "helpCenterId",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "helpCenterMappingStatus",
        "value": "LINKED"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "selections": [
      {
        "condition": "isJsmAIConfigEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "args": (v8/*: any*/),
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "settingsVirtualAgentSettingsTabFragment"
                      },
                      (v10/*: any*/)
                    ],
                    "type": "HelpCenter"
                  },
                  (v11/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "settingsBreadcrumbsFragment"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isVAOnHelpCenterGAEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "isHideKbColumnEnabled",
                "variableName": "isHideKbColumnEnabled"
              },
              {
                "kind": "Variable",
                "name": "isVAOnHelpCenterGAEnabled",
                "variableName": "isVAOnHelpCenterGAEnabled"
              }
            ],
            "kind": "FragmentSpread",
            "name": "settingsVirtualAgentSettingsTabMappedProjectsFragment"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "selections": [
      {
        "condition": "isJsmAIConfigEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "args": (v8/*: any*/),
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/),
                      {
                        "concreteType": "HelpCenterName",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "default"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "slug"
                      }
                    ],
                    "type": "HelpCenter"
                  },
                  (v11/*: any*/),
                  (v14/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isVAOnHelpCenterGAEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "condition": "isVAOnHelpCenterGAEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                  {
                    "args": (v15/*: any*/),
                    "concreteType": "JiraProjectConnection",
                    "kind": "LinkedField",
                    "name": "jiraProjectsMappedToHelpCenter",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProjectEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "small"
                                  }
                                ]
                              },
                              {
                                "condition": "isHideKbColumnEnabled",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "args": (v6/*: any*/),
                                    "kind": "LinkedField",
                                    "name": "knowledgeBaseArticlesCount",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "count"
                                          }
                                        ],
                                        "type": "KnowledgeBaseArticleCountSource"
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "name": "virtualAgentConfiguration",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "isAiResponsesEnabled"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "liveIntentsCount"
                                      }
                                    ],
                                    "type": "VirtualAgentConfiguration"
                                  },
                                  (v14/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v13/*: any*/),
                                    "type": "VirtualAgentQueryError"
                                  }
                                ]
                              },
                              (v9/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "args": (v15/*: any*/),
                    "filters": [
                      "cloudId",
                      "filter"
                    ],
                    "handle": "connection",
                    "key": "projectBreakdownTableMappedProjectsFragment_jiraProjectsMappedToHelpCenter",
                    "kind": "LinkedHandle",
                    "name": "jiraProjectsMappedToHelpCenter"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d50481bd42a382b4c4ffa9a790d41781c22c541999359c35258076f7c1ab96d5",
    "metadata": {},
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b06dee0912d03bdbc855d6535cf659a4";

export default node;
